import { ReactNode } from 'react';
import { MainFooter, MainHeader } from './components';
import Main from './components/main';

type Props = {
  children?: ReactNode;
};

export default function MainLayout({ children }: Props) {
  return (
    <div>
      <MainHeader />
      <Main>{children}</Main>
      <MainFooter />
    </div>
  );
}
