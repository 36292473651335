import React, { useEffect } from 'react';
import Router from './routes/routers';
import { clearTimeout, setTimeout } from 'worker-timers';

function App() {
  // useEffect(() => {
  //   const timer = setTimeout(() => window.location.reload(), 7200000);
  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <div style={{ flex: 1 }}>
      <Router />
    </div>
  );
}

export default App;
