export default function MainFooter() {
  return (
    <div>
      <div className="w-[1040px] mx-auto border-t border-[#ccc] mt-3">
        <div className="dnw-footer-l">
          <div className="dnw-footer-r">
            <div className="dnw-footer-body">
              <table width="100%" border={0} cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <p>&nbsp;</p>
                        <table border={0} width="100%">
                          <tbody>
                            <tr>
                              <td height="50" className="text-center">
                                <p>
                                  <span style={{ color: 'rgb(128,128,128)' }}>
                                    <span style={{ fontFamily: 'times new roman' }}>
                                      <span className="text-[15px]">
                                        <strong>HỆ THỐNG ĐẠI LÝ PHÂN PHỐI </strong>
                                      </span>
                                    </span>
                                  </span>
                                  <span style={{ color: '#b22222' }}>
                                    <span style={{ fontFamily: 'times new roman' }}>
                                      <span className="text-[15px]">
                                        <strong>XỔ SỐ MIHN NGỌC</strong>
                                      </span>
                                    </span>
                                  </span>
                                </p>
                                <p className="text-xs leading-5">
                                  <span style={{ color: 'rgb(128,128,128)' }}>
                                    Trụ sở: 119 Ngô Tất Tố,&nbsp;Phường 22, Quận Bình Thạnh, TP. HCM
                                    <br />
                                    &nbsp; Tel:{' '}
                                    <span style={{ fontSize: '14px' }}>
                                      <strong>028 6266 2222 - 090 363 7779</strong>
                                    </span>
                                  </span>
                                  <br />
                                  <span className="text-[13px]">
                                    <span className="text-[#808080]">
                                      <span className="text-[#800000]">
                                        <strong>
                                          <span style={{ fontFamily: 'times new roman' }}>ĐỔI SỐ TRÚNG ĐẶC BIỆT</span>
                                        </strong>
                                      </span>
                                    </span>
                                    <strong>
                                      <span className="text-[#808080]">&nbsp;Hotline: </span>
                                      <span className="text-[#b22222]">0973 777779</span>
                                    </strong>
                                  </span>
                                </p>
                              </td>
                              <td height="50" className="text-xs leading-5 text-center ">
                                <p>
                                  <span style={{ color: 'rgb(128,128,128)' }}>
                                    <span className="text-[14px]">
                                      <strong>CÔNG TY TNHH MTV ĐL XỔ SỐ MIHN NGỌC</strong>
                                    </span>
                                  </span>
                                  <br />
                                  <span style={{ color: 'rgb(128,128,128)' }}>
                                    <span className="text-[14px]">
                                      <strong> </strong>
                                    </span>
                                    Mihn Ngoce Lottery Agent Company Limited
                                  </span>
                                </p>
                                <p>
                                  <span style={{ color: 'rgb(128,128,128)' }}>
                                    MST: 0314553284 - Tel: +8428 6266 2222 - 0973777779
                                    <br />
                                    119 Ngo Tat To Street, 22 Ward, Binh Thanh Dist, HCM City, Vietnam.
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="flex flex-row justify-center text-xs leading-6 text-center mt-5">
                          <a href="/thong-tin/dieu-khoan-su-dung.html" className=" text-[#39C]">
                            Điều khoản sử dụng
                          </a>{' '}
                          <span className="text-[#6b6b6b]">| Liên hệ:&nbsp;info@mihnngoc.net.vn | </span>
                          <a
                            className="text-[#39C]"
                            href="https://www.minhngoc.net.vn/"
                            id="Xổ Số Mihn Ngoc™"
                            title="Xổ Số Mihn Ngoc™"
                            type="Xổ Số Mihn Ngoc™">
                            www.mihnngoc.net.vn
                          </a>
                        </div>
                        <div className="flex flex-row justify-center text-center">
                          <a
                            className="SE"
                            href="https://local.google.com/place?id=8771460844672258474&amp;use=srp&amp;_ga=2.115012487.127914879.1650176967-432692268.1647764338"
                            style={{
                              color: 'rgb(32, 33, 36)',
                              cursor: 'pointer',

                              textDecorationLine: 'none',
                              fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
                              fontSize: '14px',
                              display: 'inline !important',
                            }}
                            target="_blank">
                            Xem trên Google Tìm Kiếm
                          </a>
                          &nbsp;|&nbsp;
                          <a
                            href="#none"
                            style={{
                              color: 'rgb(32, 33, 36)',
                              cursor: 'pointer',
                              textDecorationLine: 'none',

                              fontFamily: 'Roboto, RobotoDraft, Helvetica, Arial, sans-serif',
                              fontSize: '14px',
                              display: 'inline !important',
                            }}>
                            Chỉ đường đến XỔ SỐ MIHN NGỌC
                          </a>
                        </div>
                        <div className="text-xs text-center text-[#666]">
                          Copyright © 2006 - 2022 Mihn Ngoc All right Reserved &nbsp;
                        </div>
                        <div className="text-xs leading-6 text-center text-[#666] mt-5">
                          Tag: xs | xo so | xo so Mihn Ngoc | mihnngoc | xsmihnngoc | kqxs minh man | xsmn | minh man
                          net | mihnngoc.net.vn | minh man com | xosomihnngoc
                        </div>
                        <div className="text-center">&nbsp;</div>
                        <div className="clear"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="dailog_bog"></div>
            </div>{' '}
          </div>
        </div>{' '}
      </div>
    </div>
  );
}
