import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router';
import { Types } from 'src/generated/types';
import MainLayout from 'src/layouts/mains/layout';

const Page403 = lazy(() => import('src/pages/errors/403'));
const Page404 = lazy(() => import('src/pages/errors/404'));
const Page500 = lazy(() => import('src/pages/errors/500'));

const HomePage = lazy(() => import('src/pages/home'));
const ContactUsPage = lazy(() => import('src/pages/contact-us'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));

export const mainRouters: Types.RouteObject[] = [
  {
    element: (
      <MainLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: '/', element: <HomePage /> },
      { path: 'home', element: <HomePage /> },
      { path: 'contact-us', element: <ContactUsPage /> },
    ],
  },
  {
    element: (
      <Suspense>
        <Outlet />
      </Suspense>
    ),
    children: [
      { path: '403', element: <Page403 /> },
      { path: '404', element: <Page404 /> },
      { path: '500', element: <Page500 /> },
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
    ],
  },
];
