import { url } from 'inspector';
import { useState } from 'react';
import { ImageAssets } from 'src/assets/img';

const MENU_LIST = [
  {
    nav: 'Mihn Ngoc',
    child: [
      { subNav: 'Đại Lý Vé Số', navList: [] },
      { subNav: 'Giới Thiệu Hệ Thống', navList: [] },
      { subNav: 'Liên Hệ Góp Ý', navList: [] },
    ],
  },
  {
    nav: 'Trực Tiếp',
    child: [
      { subNav: 'Trực Tiếp Xổ Số Miền Nam', navList: [] },
      { subNav: 'Trực Tiếp Xổ Số Miền Bắc', navList: [] },
      { subNav: 'Trực Tiếp Xổ Số Miền Trung', navList: [] },
      { subNav: 'Trực Tiếp Kết Quả Bóng Đá', navList: [] },
      { subNav: 'Lịch Quay Số Mở Thưởng', navList: [] },
    ],
  },
  {
    nav: 'Sổ Kết Quả',
    child: [
      { subNav: 'KQXS Miền Nam', navList: [] },
      { subNav: 'KQXS Miền Bắc', navList: [] },
      { subNav: 'KQXS Miền Trung', navList: [] },
      { subNav: 'KQXS Theo Tỉnh/TP', navList: [] },
      { subNav: 'KQXS Điện Toán', navList: [] },
    ],
  },
  {
    nav: 'Sớ Đầu Đuôi',
    child: [
      { subNav: 'Sớ Miền Nam', navList: [] },
      { subNav: 'Xem tất cả', navList: [] },
      { subNav: 'Thứ Hai', navList: [] },
      { subNav: 'Thứ Ba', navList: [] },
      { subNav: 'Thứ Tư', navList: [] },
      { subNav: 'Thứ Năm', navList: [] },
      { subNav: 'Thứ sáu', navList: [] },
      { subNav: 'hứ bảy', navList: [] },
      { subNav: 'Chủ Nhật', navList: [] },
    ],
  },
  {
    nav: 'Thống Kê',
    child: [
      {
        subNav: 'Thống Kê Theo Tỉnh',
        navList: [
          'Thống Kê Theo Tỉnh',
          'Thống Kê Lô',
          'Kiểm Tra Gan Cực Đại',
          'Thống Kê Tần Suất',
          'Thống Kê Tần Suất Chi Tiết',
        ],
      },
      {
        subNav: 'Thống Kê Theo Miền',
        navList: ['Thống Kê Lô', 'Kiểm Tra Gan Cực Đại', 'Thống Kê Tần Suất', 'Thống Kê Tần Suất Chi Tiết'],
      },
    ],
  },
  {
    nav: 'Vé Số',
    child: [
      {
        subNav: 'Vé Số Miền Nam',
        navList: [],
      },
      {
        subNav: 'Vé Số Miền Bắc',
        navList: [],
      },
      {
        subNav: 'Vé Số Miền Trung',
        navList: [],
      },
    ],
  },
  {
    nav: 'In Vé Dò',
    child: [],
  },
  {
    nav: 'Thông Tin',
    child: [],
  },
  {
    nav: 'ĐỔI SỐ TRÚNG',
    child: [],
  },
  {
    nav: 'Help ?',
    child: [
      {
        subNav: 'Hướng dẫn đăng ký thành viên, in vé dò',
        navList: [],
      },
      {
        subNav: 'Hướng Dẫn In Vé Dò Kết Quả Xổ Số',
        navList: [],
      },
      {
        subNav: 'IN VÉ DÒ TRÊN CHROME',
        navList: [],
      },
      {
        subNav: 'Có gì mới trong phiên bản này',
        navList: [],
      },
      {
        subNav: 'Lấy kết quả xổ số về websites của bạn',
        navList: [],
      },
    ],
  },
];

function RenderMenu({ itm, isGrow }: { itm: any; isGrow: boolean }) {
  const [active, setActive] = useState(false);
  return (
    <li
      className={`relative z-50 ${isGrow ? 'flex-1' : ''}`}
      onMouseOver={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      style={{
        backgroundImage: `url(${ImageAssets.MenuSepa})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left 6px',
      }}>
      <a
        href="#none"
        className={`box-content border border-transparent ${
          itm?.child?.length > 0 ? 'rounded-tr-md rounded-tl-md' : 'rounded-md'
        } leading-[38px]  text-[12px] font-bold transition-all duration-200 ease-in-out z-50 ${
          active ? 'bg-[#FEFFE5] border border-[#ccc] text-[#222] bg-none ' : 'text-white'
        }`}
        style={{
          padding: `7px 16px ${itm?.child?.length > 0 ? '12px' : '7px'}`,
        }}>
        <span>{itm?.nav?.toUpperCase()}</span>
      </a>
      {itm.child?.length > 0 && (
        <ul
          className={`absolute bg-[#feffe4] p-2 border-t-0 border border-[#ccc] rounded-br-md rounded-bl-md transition-all duration-200 ease-in-out`}
          style={{
            top: '38px',
            visibility: active ? 'visible' : 'hidden',
            left: '0px',
            width: '200px',
          }}>
          {itm?.child?.map((child: any, keyChild: any) => {
            return (
              <li key={keyChild} className={`border-t border-[#F6F6C9] text-[12px] font-bold `}>
                <a href="#none" className="flex p-1 hover:text-[red]">
                  {child?.subNav}
                </a>
                {child?.navList?.length > 0 && (
                  <ul>
                    {child?.navList?.map((c: any, t: any) => {
                      return (
                        <li key={t} className="hover:text-[red] border-t border-[#F6F6C9] text-[12px] font-normal ml-1">
                          <a href="#none" className="flex p-1">
                            {c}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
}

export default function MainHeader() {
  return (
    <div className="w-[1040px] mx-auto ">
      <div style={{ width: '100%', height: '26px', borderBottom: '1px solid #E6E6E6' }}>
        <div className="block pb-2">
          <div className="h-[25px] flex flex-row justify-between ">
            <strong className="text-xs leading-[25px]">
              Xổ Số Mihn Ngoc™ » XSMN » Kết quả xổ số trực tiếp » Đổi Số Trúng » www.minhngoc.net.vn
            </strong>

            <div className="flex flex-row leading-[25px] items-center gap-1">
              <a href="#none" title="Đăng ký thành viên" className="text-xs text-[#39C] font-bold">
                Đăng ký
              </a>
              <span className="text-xs ">Đăng nhập</span>
              <input
                type="text"
                name="username"
                id="username"
                className="text-xs border border-solid border-gray-400 rounded-[4px] shadow-[inset_0_-2px_4px_rgba(0,0,0,0.2)] px-1"
                style={{ width: '112px' }}
                value="Tên đăng nhập"
              />
              <input
                type="text"
                name="passwords"
                id="passwords"
                className="text-xs border border-solid border-gray-400 rounded-[4px] shadow-[inset_0_-2px_4px_rgba(0,0,0,0.2)] px-1"
                style={{ width: '112px' }}
                value="Password"
              />
              <input
                type="password"
                name="password"
                id="password"
                className="text-xs"
                style={{ width: '100px', display: 'none' }}
              />
              <label className="text-xs flex flex-row gap-1 leading-[25px] ml-1">
                <input type="checkbox" id="cookieuser" name="cookieuser" value="1" />
                Ghi nhớ
              </label>

              <input
                name="Submit"
                type="submit"
                id="login"
                value="Login"
                className="text-xs p-y px-[5px] text-white border border-solid border-gray-400 rounded-[3px]"
                style={{
                  backgroundImage: `url(${ImageAssets.LoginButtonBg})`,
                  backgroundRepeat: 'repeat',
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-1 justify-between mt-1">
        <a href="/">
          <div className="min-w-[320px]">
            <img src={ImageAssets.Logo} alt="" width={'100px'} />
          </div>
        </a>
        <div className="flex flex-col min-w-[405px] w-[405px]">
          <div>
            <div className="MsoNormal" style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '8px' }}>
                <strong>&nbsp; </strong>
              </span>
            </div>
            <div className="text-xs" style={{ textAlign: 'center' }}>
              <strong>CHUYÊN TRANG TRỰC TIẾP KẾT QUẢ XỔ SỐ</strong>
            </div>
            <a href="/">
              <div className="MsoNormal" style={{ textAlign: 'center' }}>
                <span style={{ fontSize: '28px' }}>
                  <span style={{ color: 'rgb(178, 34, 34)' }}>
                    <span style={{ fontFamily: 'times new roman,times,serif' }}>
                      <strong>MIHN NGOC</strong>
                    </span>
                  </span>
                </span>
              </div>
            </a>
          </div>
          <div className="mt-2">
            <ul className="flex flex-row gap-0.5">
              {['Home', 'Miền Nam', 'Miền Trung', 'Miền Bắc & Điện Toán'].map((x, i) => {
                return (
                  <li
                    key={i}
                    className="px-[11px] py-1 border border-b-0 border-solid border-[#CCC] text-center text-[13px] font-bold text-[#445058] rounded-tl-[4px] rounded-tr-[4px] hover:bg-[#BF0000] hover:text-white overflow-hidden"
                    style={{ backgroundImage: `url(${ImageAssets.BgRad50})` }}>
                    <a href="#none" className="home">
                      {x}
                    </a>
                  </li>
                );
              })}
            </ul>{' '}
          </div>
        </div>
        <div className="min-w-[302px] mt-0">
          <div className="border border-b-[3px] border-solid border-[#C75B00] bg-[#feffe4] rounded-[4px] overflow-hidden">
            <div
              className="h-8"
              style={{
                backgroundImage: `url(${ImageAssets.BgBoxTk})`,
                backgroundRepeat: 'repeat-x',
                backgroundPosition: '0 -7px',
              }}>
              <h6 className="text-xs font-bold leading-8 px-2">NET Dò vé số Online - May mắn mỗi ngày!...</h6>
            </div>
            <div className="mt-1 pb-2">
              <div className="flex flex-row px-2 gap-1">
                <div className="flex flex-row items-center leading-3 pr-3">
                  <label className="w-[40px] text-xs leading-3">Ngày:</label>
                  <div className="w-[83px] border border-[#767676] bg-white  min-h-[16px]">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value="22-01-2025"
                      className="text-xs "
                      style={{ width: '100px', display: 'none' }}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center  pr-3">
                  <label className="w-[40px] text-xs leading-3">Tỉnh:</label>
                  <div
                    className="w-[83px]leading-[18px] min-h-[16px] border border-[#767676] bg-white"
                    style={{ width: '100px' }}>
                    <select name="tinh" className="text-[11px] leading-5">
                      <option value="0">Chọn tỉnh</option>
                      <option value="48">Miền bắc</option>
                      <option value="11">Cần Thơ</option>
                      <option value="30">Đà Nẵng</option>
                      <option value="10">Đồng Nai</option>
                      <option value="31">Khánh Hòa</option>
                      <option value="12">Sóc Trăng</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between mt-2 px-2">
                <div className="flex flex-row leading-3">
                  <label className="w-[40px]  text-xs leading-3">Vé Số:</label>
                  <div className="w-[83px] h-5 border border-[#767676] bg-white"></div>
                </div>
                <div>
                  <button
                    className="text-white"
                    style={{
                      background: `url(${ImageAssets.MenuSprite})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '-103px -88px',
                      position: 'relative',
                      width: '99px',
                      height: '29px',
                      font: ' 700 12px / 28px arial, sans-serif',
                      textAlign: 'center',
                      display: 'block',
                      textDecoration: 'none',
                    }}>
                    Dò Kết Quả
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[38px]" style={{ background: 'left 0 no-repeat', marginBottom: '5px' }}>
        <div
          style={{
            background: `url(${ImageAssets.BgMenu}) right -84px no-repeat`,
          }}>
          <div
            className="h-[38px] px-3 w-full"
            style={{
              background: `url(${ImageAssets.BgMenu}) right -41px repeat-x`,
            }}>
            <div>
              <div className="">
                <ul className="flex flex-row">
                  {MENU_LIST?.map((itm, key) => {
                    const length = MENU_LIST?.length - 1;

                    return <RenderMenu key={key} itm={itm} isGrow={length === key + 1 ? true : false} />;
                  })}
                </ul>{' '}
              </div>{' '}
            </div>{' '}
          </div>
        </div>{' '}
      </div>
    </div>
  );
}
