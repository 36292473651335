const ImageAssets = {
  Arrow1: require('./arrow_1.gif'),
  ArrowDownGold: require('./arrow_down_gold.png'),
  BgBoxTk: require('./bg_boxtk.png'),
  BgMenu: require('./bgmenu.png'),
  BgRad50: require('./bgrad50.png'),
  BkqBottom1: require('./bkq-bottom1.png'),
  BkqBottom2: require('./bkq-bottom2.png'),
  BkqBottom3: require('./bkq-bottom3.png'),
  BkqTop1: require('./bkq-top1.png'),
  BkqTop2: require('./bkq-top2.png'),
  BkqTop3: require('./bkq-top3.png'),
  Choxoso: require('./choxoso.gif'),
  Dudoan: require('./dudoan.png'),
  Ic: require('./ic.png'),
  IconThongke: require('./icon-thongke.gif'),
  Loading: require('./loading.gif'),
  LoginButtonBg: require('./loginbuttonbg.png'),
  Logo: require('./logo.png'),
  MenuSepa: require('./menu-sepa.png'),
  CalenderToday: require('./calendar-today.png'),
  MenuSprite: require('./menu-sprite.png'),
  NavLeft: require('./nav-left.gif'),
  NavLefu: require('./nav-lefu.gif'),
  NavRight: require('./nav-righ.gif'),
  NavRigi: require('./nav-rigi.gif'),
  OkDaxosoxong: require('./ok_daxosoxong.png'),
  Old: require('./old.gif'),
  RandomRed: require('./random-red.gif'),
  Random: require('./random.gif'),
  Tools: require('./tools.png'),
  TrucTiepxoso: require('./tructiepxoso.gif'),
  Doisotrung: require('./doisotrung.gif'),
  Veso: require('./veso.gif'),
  Print: require('./print.gif'),
};

export { ImageAssets };
